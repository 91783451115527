<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" xl="8">
        <ent-component />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EntComponent from "@/components/ent/EntComponent.vue";
export default {
  components: {
    EntComponent,
  },
  mounted () {
    const sendAppheaderItem = {
        method: "entHeader",
        item: "on",
      };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  destroyed () {
      const sendAppheaderItem = {
        method: "entHeader",
        item: "off",
      };
      this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style></style>
