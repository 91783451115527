<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <search-component :ent="true" @ent_search="ent_search" />
      </v-col>
      <v-col cols="12" style="margin-bottom: 100px">
        <v-container>
          <v-row v-for="(data, i) in category" :key="i">
            <v-col cols="12" class="text-h6" align-self="start">
              <v-icon color="#C1C1C1">mdi-circle-small</v-icon>{{ data.title }}
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <span v-for="(btndata, j) in data.data" :key="j">
                <v-btn
                  elevation="0"
                  color="#146F83"
                  outlined
                  rounded
                  class="ma-1"
                  v-ripple="{ class: `my_color0--text` }"
                  @click="clickKeywordBtn(btndata.keyword)"
                  style="font-size: 16px"
                >
                  {{ btndata.keyword }}
                </v-btn>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import DefaultPopup from "../modal/DefaultPopup.vue";
import SearchComponent from "../SearchComponent.vue";

export default {
  components: { SearchComponent, DefaultPopup },
  data() {
    return {
      popupSet: {},
      category: [
        {
          title: "주증상",
          data: [],
        },
        {
          title: "병명",
          data: [],
        },
        {
          title: "수술명",
          data: [],
        },
        {
          title: "검사명",
          data: [],
        },
        {
          title: "백신",
          data: [],
        },
      ],
      radioGroup: "0",
      searchWord: "",
      icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
      loader: null,
      loading: false,
      popupSet: {},
      isTopPriority: true,
      popupSetAddress: {},
      form: {
        address: {
          addr: {},
          value: "",
          buildingname: {
            value: "",
            flag: false,
            hasError: false,
            errorTxt: "주소를 입력해주세요",
          },
          zonecode: {
            value: "",
          },
          bname: {
            value: "",
          },
          aim: {
            value: "",
          },
          locationPoint: {
            posX: 1,
            posY: 1,
          },
        },
      },
    };
  },
  created() {
    this.getKeywordList();
  },
  computed: {
    ...mapState("basic", ["checkAddressPopup", "checkPopup"]),
    ...mapGetters("asso", ["GET_KEYWORD_LIST"]),
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_ADDRESS_POPUP", "SET_POPUP"]),
    ...mapActions("join", ["FETCH_BASIC_ADDRESS"]),
    ...mapActions("asso", ["BRING_KEYWORD_LIST"]),
    ent_search(text) {
      var saveData = {};
      saveData = {
        address: "",
        location: this.form.address.locationPoint,
        searchWord: "이비인후과 " + text,
      };

      window.localStorage.setItem("otoForm", JSON.stringify(saveData));
      this.$router.push("/entlist").catch(() => {});
    },
    clickKeywordBtn(keyword) {
      // console.log(keyword);
      var saveData = {};
      saveData = {
        address: "",
        location: this.form.address.locationPoint,
        searchWord: "이비인후과 " + keyword,
      };

      window.localStorage.setItem("otoForm", JSON.stringify(saveData));
      this.$router.push("/entlist").catch(() => {});
    },
    async getKeywordList() {
      await this.BRING_KEYWORD_LIST();

      for (let i = 0; i < this.GET_KEYWORD_LIST.keyword.length; i++) {
        if (this.GET_KEYWORD_LIST.keyword[i].keyStus === "S") {
          this.category[0].data.push(this.GET_KEYWORD_LIST.keyword[i]);
        }
        if (this.GET_KEYWORD_LIST.keyword[i].keyStus === "D") {
          this.category[1].data.push(this.GET_KEYWORD_LIST.keyword[i]);
        }
        if (this.GET_KEYWORD_LIST.keyword[i].keyStus === "O") {
          this.category[2].data.push(this.GET_KEYWORD_LIST.keyword[i]);
        }
        if (this.GET_KEYWORD_LIST.keyword[i].keyStus === "E") {
          this.category[3].data.push(this.GET_KEYWORD_LIST.keyword[i]);
        }
        if (this.GET_KEYWORD_LIST.keyword[i].keyStus === "K") {
          this.category[4].data.push(this.GET_KEYWORD_LIST.keyword[i]);
        }
      }
    },
    goDrReferRegister() {
      this.$router.push("/v/join/agreement").catch(() => {});
    },
    goDrRefer() {
      this.$router.push("/").catch(() => {});
    },
    goHome() {
      //this.$router.push("/asso/entMain");
      location.reload();
    },

    btnSearch() {
      // console.log(this.radioGroup);
      var saveData = {};
      window.localStorage.setItem("otoForm", "");
      if (this.searchWord !== null && this.searchWord !== "") {
        if (this.radioGroup === "0") {
          saveData = {
            address: "",
            location: this.form.address.locationPoint,
            searchWord: "이비인후과 " + this.searchWord,
          };
          // console.log(saveData);
        } else if (this.radioGroup === "1") {
          this.loader = "loading";

          saveData = {
            address: this.form.address.addr,
            location: this.form.address.locationPoint,
            searchWord: "이비인후과 " + this.searchWord,
          };
          // console.log(saveData);
        }
        window.localStorage.setItem("otoForm", JSON.stringify(saveData));
        this.$router.push("/entlist").catch(() => {});
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "검색어에 공백을 입력하실 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    searchAddress() {
      this.SET_ADDRESS_POPUP(true);
      this.popupSet.popType = "address";
    },
    async getAddress(address) {
      /*var addr = encodeURI(address.address,"utf-8");
      var api = "https://naveropenapi.apigw.ntruss.com/map-geocode/v2/geocode?query="+addr;
      console.log(addr + api)*/
      let result = null;
      await this.FETCH_BASIC_ADDRESS(address.address).then((data) => {
        // console.log("data : ", data);
        result = data.result;
        this.form.address.locationPoint.posX = data.posX;
        this.form.address.locationPoint.posY = data.posY;

        /** API 통신해서 받아온 문자열 */
      });

      ////////////////////////////////////////////
      // console.log("addr", address);
      if (result === false) {
        this.SET_POPUP(true);
        this.popupSet.title = "";
        this.popupSet.content = "위치정보를 받아오지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.SET_ADDRESS_POPUP(false);
      }
      // console.log("우편주소", address.zonecode);
      // console.log("주소1", address.buildingName);
      // console.log("주소2", address.bname);
      else {
        this.form.address.value = address.address;
        this.form.address.addr = address;
        // console.log("addr", address);
        this.form.address.buildingname.flag = true;
        this.form.address.buildingname.hasError = false;
        this.form.address.buildingname.value =
          address.address + " " + address.buildingName;
        this.form.address.zonecode.value = address.zonecode;
        this.form.address.bname.value = address.bname;
        this.form.address.aim.value = address.sido + " " + address.sigungu;
        this.SET_ADDRESS_POPUP(false);
        if (document.querySelector("body").classList.contains("spreaded")) {
          document.querySelector("body").classList.remove("spreaded");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--outlined {
  border: thin solid #c1c1c1;
}
</style>
